import "./DataNotAvailable.scss";

function DataNotAvailable() {
  return (
    <div className="dataNotAvailable mt-4 p-5">
      <h2>No Data Available</h2>
    </div>
  );
}

export default DataNotAvailable;
